import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";

const DashboardIframe = (props) => {
  const { data } = props;
  const [displayIframe, setDisplayIframe] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (data) {
      checkIframe();
    }
    // eslint-disable-next-line
  }, [data]);

  function checkIframe() {
    var xhr = new XMLHttpRequest();
    xhr.addEventListener("load", () => {
      setIsLoading(false);
      setDisplayIframe(true);
    });
    xhr.addEventListener("error", () => {
      setIsLoading(false);
      setDisplayIframe(false);
    });
    xhr.open("HEAD", data.video.monitor);
    xhr.send();
  }
  return (
    <React.Fragment>
      {displayIframe && (
        <iframe
          title={data.address + "/program"}
          id="webrtcProgramOutput"
          allowusermedia=""
          allow="autoplay;"
          src={data.video.monitor}
        ></iframe>
      )}
      {isLoading && <Spinner size="md" color="white" />}
      {!isLoading && !displayIframe && <i className="fas fa-ban"></i>}
    </React.Fragment>
  );
};

export default DashboardIframe;

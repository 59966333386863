import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";

import { useInterval } from "../../Base/Hooks/interval";
import bars from "../Images/bars.jpg";
import notfound from "../Images/notfound.png";
import preview from "../Images/preview.jpg";

const DashboardImage = (props) => {
  const { data, image, defaultImage } = props;
  const [displayImage, setDisplayImage] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [silentLoading, setSilentLoading] = useState(false);

  useEffect(() => {
    if (data) {
      checkImage(image, defaultImage);
    }
    // eslint-disable-next-line
  }, [data]);

  useInterval(() => {
    if (data) {
      setSilentLoading(true);
      checkImage(image, defaultImage);
    }
  }, 10000);

  const checkImage = (image, defaultImage) => {
    setIsLoading(true);
    var img = new Image();
    img.src = data[image];
    img.onload = function () {
      setDisplayImage(data[image] + "?t=" + Date.now());
      setDisplayName(null);
      setIsLoading(false);
    };
    img.onerror = function () {
      if (defaultImage === "bars") {
        setDisplayImage(bars);
        setDisplayName(data.name);
      } else {
        if (defaultImage === "preview") {
          setDisplayImage(preview);
        } else {
          setDisplayImage(notfound);
        }
        setDisplayName(null);
      }
      setIsLoading(false);
      setSilentLoading(false);
    };
  };

  return (
    <React.Fragment>
      {(!isLoading || silentLoading) && (
        <React.Fragment>
          <img src={displayImage} alt={image} />
          {displayName && <span className="displayName">{displayName}</span>}
        </React.Fragment>
      )}
      {isLoading && !silentLoading && <Spinner size="md" color="white" />}
    </React.Fragment>
  );
};

export default DashboardImage;

export const ENVIRONMENT_LOCAL = "dev";
export const ENVIRONMENT_DEFAULT = "prod";

const SENTRY_COMMON = {
  dsn: "https://32e976c6bbc14243817751274fcbd5e7@o1035515.ingest.sentry.io/6025644",
  release: "1.0.0", // TODO: Add application versioning
  tracesSampleRate: 1.0
};

const DEV_HOST = import.meta.env.REACT_APP_API_HOST || "localhost";
const DEV_PORT = import.meta.env.REACT_APP_API_PORT || "8088";
const LOCAL = (import.meta.env.REACT_APP_LOCAL || "no") === "yes";
const DEV_LOCAL_API = `http://${DEV_HOST}:${DEV_PORT}`;

export const envBaseDomainMappings = {
  dev: "dev.connect-nonprod.sportradar.dev",
  stg: "stg.connect-nonprod.sportradar.dev",
  prod: "connect.sportradar.com",
}

// TODO: refactoring
export const environments = {
  location: {
    dev: LOCAL ? DEV_LOCAL_API : "https://api.vm.dev.connect-nonprod.sportradar.dev",
    stg: LOCAL ? DEV_LOCAL_API : "https://api.vm.stg.connect-nonprod.sportradar.dev",
    prod: LOCAL ? DEV_LOCAL_API : "https://api.vm.connect.sportradar.com",
  },
  token: {
    dev: "https://token.dev.connect-nonprod.sportradar.dev",
    stg: "https://token.stg.connect-nonprod.sportradar.dev",
    prod: "https://token.connect.sportradar.com",
  },
  basedomain: {
    dev: LOCAL ? "localhost" : "dev.connect-nonprod.sportradar.dev",
    stg: LOCAL ? "localhost" : "stg.connect-nonprod.sportradar.dev",
    prod: LOCAL ? "localhost" : "connect.sportradar.com",
  },
  envProductLinksMap: {
    local: {
      "admin.dc": "https://admin.dc.dev.connect-nonprod.sportradar.dev",
      "evo": "https://evo.sandpit.cloud.atriumsports.com",
      "ca": "https://ca.sandpit.cloud.atriumsports.com",
      "pm": "https://pm.dev.connect-nonprod.sportradar.dev",
      "vm": "https://vm.dev.connect-nonprod.sportradar.dev",
      "um": "https://um.sandpit.cloud.atriumsports.com",
    },
    dev: {
      "admin.dc": "https://admin.dc.dev.connect-nonprod.sportradar.dev",
      "evo": "https://evo.sandpit.cloud.atriumsports.com",
      "ca": "https://ca.sandpit.cloud.atriumsports.com",
      "pm": "https://pm.dev.connect-nonprod.sportradar.dev",
      "vm": "https://vm.dev.connect-nonprod.sportradar.dev",
      "um": "https://um.sandpit.cloud.atriumsports.com",
    },
    stg: {
      "admin.dc": "https://admin.dc.stg.connect-nonprod.sportradar.dev",
      "evo": "https://evo.nonprod.cloud.atriumsports.com",
      "ca": "https://ca.nonprod.cloud.atriumsports.com",
      "pm": "https://pm.stg.connect-nonprod.sportradar.dev",
      "vm": "https://vm.stg.connect-nonprod.sportradar.dev",
      "um": "https://um.nonprod.cloud.atriumsports.com",
    },
    prod: {
      "admin.dc": "https://admin.dc.connect.sportradar.com",
      "evo": "https://evo.prod.cloud.atriumsports.com",
      "ca": "https://ca.prod.cloud.atriumsports.com",
      "pm": "https://pm.connect.sportradar.com",
      "vm": "https://vm.connect.sportradar.com",
      "um": "https://um.prod.cloud.atriumsports.com",
    },
  }
};

export const getEnvironment = (defaultEnv) => {
  let host = window.location.hostname;
  let domainEnv = defaultEnv; // if not specified
  if (host.includes("localhost")) {
    domainEnv = ENVIRONMENT_LOCAL;
  } else {
    // host names to env stages
    for (const [ env, domain ] of Object.entries(environments.basedomain)) {
      // console.debug(env, "Host", host, "includes domain", domain, host.includes(domain));
      if (host.includes(domain)) {
        domainEnv = env;
        break;
      }
    }
  }
  const nodeEnv = import.meta.env.NODE_ENV;
  const searchParams = new URLSearchParams(window.location.search);
  const queryEnv = searchParams.get("env");
  const buildEnv = import.meta.env.REACT_APP_ENVIRONMENT;
  // Env inference
  let decodedEnv = domainEnv; // infer from domain (keep backwards compatibility)
  decodedEnv = buildEnv || decodedEnv; // infer from build
  decodedEnv = queryEnv || decodedEnv; // infer from query string
  console.debug(`LOCAL=(${LOCAL}) - Current environment is`, decodedEnv, {
    nodeEnv,
    domainEnv,
    buildEnv,
    queryEnv,
  });
  return decodedEnv;
};

export const ENVIRONMENT = getEnvironment(ENVIRONMENT_DEFAULT);

export const getSentryConfig = () => {
  return {
    ...SENTRY_COMMON,
    // Auto-inject current environment
    environment: ENVIRONMENT,
    // Disable tracing for local domains unless enforced by the developer
    denyUrls: import.meta.env.REACT_APP_TRACING_FORCE
      ? []
      : ["0.0.0.0", "localhost", "127.0.0.1", "::1"],
    debug: typeof import.meta.env.REACT_APP_TRACING_DEBUG != "undefined",
  };
};
